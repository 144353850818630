<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import {
  fetchSuppliersListApi,
  fetchUserStructuresTypesApi,
  fetchDirectionsListApi,
  fetchDivisionsListApi,
  fetchDepartmentsListApi,
  fetchServicesListApi,
  fetchRolesListApi,
} from "@/api/common";
import { required } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouvel Employé",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  import: {
    fetchSuppliersListApi,
    fetchUserStructuresTypesApi,
    fetchDirectionsListApi,
    fetchDivisionsListApi,
    fetchDepartmentsListApi,
    fetchServicesListApi,
    fetchRolesListApi,
  },
  data() {
    return {
      title: "Nouvel Employé Prestataire",
      uoms: [],
      categories: [],
      waerhouses: [],
      contracts: [],
      items: [
        {
          text: "RH",
          href: "/",
        },
        {
          text: "Nouvel Employé Prestataire",
          active: true,
        },
      ],
      directions: [],
      divisions: [],
      departments: [],
      services: [],
      roles: [],
      userStructuresTypes: [],
      empForm: {
        id_number: "",
        first_name: "",
        email: "",
        last_name: "",
        birthdate: "",
        functionName: "",
        hasUserAccount: "",
        structureType: "",
        direction: "",
        division: "",
        department: "",
        service: "",
        username: "",
        password: "",
        role: "",
      },

      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    empForm: {
      id_number: { required },
      first_name: { required },
      last_name: { required },
      email: { required },
      functionName: { required },
      structureType: { required },
    },

  },
  created: function() {
    this.fetchUserStructuresTypes();
    this.fetchRolesList();
  },
  methods: {
    fetchUserStructuresTypes() {
      fetchUserStructuresTypesApi()
        .then((res) => (this.userStructuresTypes = res.data.original.data))
        .catch(() => {})
        .finally(() => {});
    },
    fetchSuppliersList() {
      fetchSuppliersListApi()
        .then((res) => (this.suppliers = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchRolesList() {
      fetchRolesListApi()
        .then((res) => (this.roles = res.data.original.data))
        .catch(() => {})
        .finally(() => {});
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      var router = this.$router;
      alert(this.empForm.hasUserAccount);
      this.$http
        .post("/base/employees_prestataire/store", this.empForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "base.hr.employees_prestataire" });

              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Numéro employé *</label>
                    <input
                      id="deptName"
                      v-model="empForm.id_number"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{
                        'is-invalid': submitted && $v.empForm.id_number.$error,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Nom *</label>
                    <input
                      id="deptName"
                      v-model="empForm.first_name"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{
                        'is-invalid': submitted && $v.empForm.first_name.$error,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Prénom *</label>
                    <input
                      id="deptName"
                      v-model="empForm.last_name"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{
                        'is-invalid': submitted && $v.empForm.last_name.$error,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Email *</label>
                    <input
                      id="deptName"
                      v-model="empForm.email"
                      type="email"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{
                        'is-invalid': submitted && $v.empForm.email.$error,
                      }"
                    />
                  </div>
                </div>

                 <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Fonction *</label>
                    <input
                      id="deptName"
                      v-model="empForm.functionName"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{
                        'is-invalid': submitted && $v.empForm.functionName.$error,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Code de l'employée *</label>
                     <input
                      id="deptName"
                      v-model="empForm.structureType"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{
                        'is-invalid': submitted && $v.empForm.structureType.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.empForm.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.empForm.email.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="empForm.structureType.id == 'direction'"
                >
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >{{ empForm.structureType.name }} *</label
                    >
                    <multiselect
                      v-model="empForm.direction"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="directions"
                      placeholder="Select user Direction"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ direction }">{{
                        direction.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="empForm.structureType.id == 'division'"
                >
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >{{ empForm.structureType.name }} *</label
                    >
                    <multiselect
                      v-model="empForm.division"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="divisions"
                      placeholder="Select user Division"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="empForm.structureType.id == 'department'"
                >
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >{{ empForm.structureType.name }} *</label
                    >
                    <multiselect
                      v-model="empForm.department"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="departments"
                      placeholder="Select user Department"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="empForm.structureType.id == 'service'"
                >
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >{{ empForm.structureType.name }} *</label
                    >
                    <multiselect
                      v-model="empForm.service"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="services"
                      placeholder="Select user Service"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <b-form-checkbox
                    v-model="empForm.hasUserAccount"
                    switch
                    class="mb-3"
                    >Compte utilisateur
                  </b-form-checkbox>
                </div>

                <div class="col-md-3"  v-if="empForm.hasUserAccount">
                  <div class="form-group">
                    <label for="deptName">Nom d'utilisateur</label>
                    <input
                      id="deptName"
                      v-model="empForm.username"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                    />
                  </div>
                </div>

                <div class="col-md-3"  v-if="empForm.hasUserAccount">
                  <div class="form-group">
                    <label for="deptName">Mot de passe</label>
                    <input
                      id="deptName"
                      v-model="empForm.password"
                      type="password"
                      class="form-control"
                      placeholder=""
                      value=""
                    />
                  </div>
                </div>
                <div class="col-md-3"  v-if="empForm.hasUserAccount">
                  <div class="form-group">
                    <label for="formrow-email-input">Rôle</label>
                    <multiselect
                      v-model="empForm.role"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="roles"
                      placeholder="Select user Service"
                      :allow-empty="false"
                    >
                      <template slot="singleRole" slot-scope="{ role }">{{
                        role.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary"
                :disabled="$v.empForm.$invalid"
                type="submit"
              >
                Enregistrer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
